import '@babel/polyfill';
import 'nodelist-foreach-polyfill';
import React from 'react';
import PropTypes from 'prop-types';
import render from '@rmb185/react-renderhelper';
import CustomLazyLoad from '../components/CustomLazyLoad';

const containers = document.querySelectorAll('[data-component-type]');


class ModuleLoader extends React.Component {

  static defaultProps = {
    moduleName: PropTypes.string,
    config: PropTypes.object,
  }

  state = {
    module: null,
  }

  componentDidMount = () => {
    import(`js/components/${this.props.moduleName}`).then((Module) => {

      if (/(127\.0\.0\.1)|(localhost)/.test(window.location.hostname)) {
        console.log(this.props.moduleName, this.props.config);
      }

      this.setState({
        module: <Module.default {...this.props.config} />,
      })

    }).catch((err) => {
      console.trace(err, this.props.moduleName);
    })
  }

  render() {
    return this.state.module ? this.state.module : <div style={{height:'100vh'}} />;
  }

}

containers.forEach((el, i) => {
  let config = {},
    clsName = el.dataset.componentType;

    config = el.dataset.config || '{}';

    config = JSON.parse(config);

    if (!/(BigIntroHero)|SmallIntroHero|AlertBar|FindADepot|NewsLandingPage/.test(clsName)) {
      render(
        <CustomLazyLoad height={500}>
          <ModuleLoader config={config} moduleName={clsName} />
        </CustomLazyLoad>,
        el
      );
    } else {
      render(<ModuleLoader config={config} moduleName={clsName} />, el);
    }

});
